import { Component, Input, inject, input, signal } from '@angular/core';
import { PartTabsComponent } from '../parts/part-tabs.component';
import { Part } from '../core/models/part';
import { PartsService } from '../core/parts.service';
import { ApplicationInfoService } from '../core/application-info.service';

@Component({
    selector: 'app-featured-parts',
    standalone: true,
    template: `
        <div class="page home-page">
            <div class="page-body">
                <app-part-tabs [parts]="parts()" [pageSize]="8" />
            </div>
        </div>
    `,
    styles: ``,
    imports: [PartTabsComponent],
})
export class FeaturedPartsComponent {
    appInfo = inject(ApplicationInfoService);
    partsService = inject(PartsService);
    parts = signal<Part[]>([]);

    // shopUrl = input.required<string>();
    @Input()
    shopUrl: string = 'https://shop.northamericandieselparts.com';

    async ngOnInit() {
        console.log('shopUrl', this.shopUrl);
        this.appInfo.shopUrl.set(this.shopUrl);
        await this.appInfo.getApplicationInfo();
        let parts = await this.partsService.getParts({ hasPictures: true, isFeatured: true, hasPrice: true, take: 8 });
        this.parts.set(parts);
    }
}
