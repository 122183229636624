import { CommonModule } from '@angular/common';
import { Component, Input, WritableSignal } from '@angular/core';
import { Part } from '../core/models/part';
import { PartGridComponent } from './part-grid.component';

@Component({
    selector: 'app-part-tabs',
    standalone: true,
    imports: [CommonModule, PartGridComponent],
    template: `
        <div id="homePageQuickTabs" class="productTabs ui-tabs ui-corner-all ui-widget ui-widget-content">
            <div class="productTabs-header">
                <ul role="tablist" class="ui-tabs-nav ui-corner-all ui-helper-reset ui-helper-clearfix ui-widget-header">
                    <li role="tab" tabindex="0" class="ui-tabs-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active">
                        <a role="presentation" tabindex="-1" class="ui-tabs-anchor">
                            Featured products
                        </a>
                    </li>
                    <!-- <li role="tab" tabindex="-1" class="ui-tabs-tab ui-corner-top ui-state-default ui-tab">
                        <a href="/#quickTab-homepage-bestsellers" role="presentation" tabindex="-1" class="ui-tabs-anchor" id="ui-id-2"
                            >Bestsellers</a
                        >
                    </li>
                    <li role="tab" tabindex="-1" class="ui-tabs-tab ui-corner-top ui-state-default ui-tab">
                        <a href="/#quickTab-homepage-newproducts" role="presentation" tabindex="-1" class="ui-tabs-anchor" id="ui-id-3"
                            >New products</a
                        >
                    </li> -->
                </ul>
            </div>
            <div class="productTabs-body">
                <div
                    id="quickTab-homepage-products"
                    role="tabpanel"
                    class="ui-tabs-panel ui-corner-bottom ui-widget-content"
                    aria-hidden="false"
                >
                    <app-part-grid [parts]="parts" [pageSize]="pageSize"></app-part-grid>
                </div>
            </div>
        </div>
    `,
    styles: ``,
})
export class PartTabsComponent {
    @Input() parts!: Part[];
    @Input() pageSize: number = 9;
}
